@import 'styles/layers.scss';

@layer component {
  .content {
    margin-bottom: 40px;
  }
  
  .title {
    margin-left: 10px;
  }
  
  .link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .link, .link:visited, .link:hover {
    text-decoration: none;
    font-size: 12px;
    color: var(--white);
  }
}