@import 'styles/layers.scss';

@layer component {
  .wrap {
    border-radius: 60px;
    background: var(--black3);
    padding: 0px 6px;
  }

  .green {
    color: var(--green);
  }

  .red {
    color: var(--red);
  }

  .orange {
    color: var(--orange1);
  }
}