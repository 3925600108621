// .content {
//   border-radius: 5px;
//   animation-duration: 5s;
//   animation-iteration-count: infinite;
//   animation-fill-mode: forwards;
//   animation-timing-function: linear;
// }

// .dark {
//   background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.04) 107.18%);
//   animation-name: animation-dark;
// }

// .light {
//   background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.04) 107.18%);
//   animation-name: animation-light;
// }

// @keyframes animation-dark {
//   0%  { background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.04) 107.18%); }
//   25% { background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.15) 50.18%, rgba(255, 255, 255, 0.04) 107.18%); }
//   50% { background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.15) 107.18%); }
//   75% { background: linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.15) 50.18%, rgba(255, 255, 255, 0.04) 107.18%); }
//   100% { background: linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.04) 107.18%); }
// }

// @keyframes animation-light {
//   0%  { background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.04) 107.18%); }
//   25% { background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.04) 50.18%, rgba(0, 0, 0, 0.15) 107.18%); }
//   50% { background: linear-gradient(90deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.15) 107.18%); }
//   75% { background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.04) 50.18%, rgba(0, 0, 0, 0.15) 107.18%); }
//   100% { background: linear-gradient(90deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.04) 107.18%); }
// }

@import 'styles/layers.scss';

@layer uikit {
  .content {
    border-radius: 5px;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: animation;
  }
  
  .dark {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.00));
    background-size: 200% 200%;
  }
  
  @keyframes animation {
    0%{ background-position: 0% 0% }
    50%{ background-position: 91% 100% }
    100%{ background-position: 0% 0% }
  }
  
}