@import 'styles/layers.scss';

@layer uikit {
  .bar {
    width: 6px;
    min-width: 6px;
    height: 12px;
    border-radius: 4px;
  }
  
  .green {
    background-color: var(--green);
  }
  
  .yellow {
    background-color: var(--yellow);
  }
  
  .red {
    background-color: var(--red3);
  }
  
  .grey {
    background-color: var(--white1);
  }
  
  .mr {
    margin-right: 8px;
  }
  
  .spinner {
    width: 14px;
    height: 14px;
    color: var(--orange1);
  }
  
}