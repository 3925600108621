@import 'styles/layers.scss';

@layer component {
  .papers {
    column-gap: 40px;
    row-gap: 40px;
  }
  
  .divider-vertical {
    height: 97px;
  }
}