@import 'styles/layers.scss';

@layer uikit {
  .content {
    cursor: pointer;
    background-color: transparent;
    color: var(--white);
    border: none;
    outline: none;
    padding: 0;
  }
}