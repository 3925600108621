@import 'styles/layers.scss';

@layer uikit {
  .dots {
    transform: rotate(90deg);
    font-size: 24px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    padding-bottom: 15px;
    user-select: none;
  }
  
  .dots:hover {
    color: var(--blue);
  }
}