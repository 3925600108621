@import 'styles/layers.scss';

@layer component {
  .table-header {
    margin-bottom: 24px;
  }

  .edit {
    padding: 9px 13px;
    margin-right: 10px;
    font-size: 12px;
    width: 53px;
  }

  .adder-btn, .edit {
    height: 25px;
  }
}
